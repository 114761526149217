@cert-width: 375px;
@cert-padding: calc(20 / 375 * @cert-width);
@font-size: calc(13 / 375 * @cert-width);

.cert-container {
  width: @cert-width !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: @font-size;
  box-sizing: border-box;

  .cert-wrapper {
    box-sizing: border-box;
    padding: @cert-padding;
    padding-bottom: calc(12 / 375 * @cert-width);
    color: fade(white, 90);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: #616161;
    width: @cert-width;
    // min-height: calc(@cert-width / 9 * 16);
    border-radius: calc(24 / 375 * @cert-width);
    position: relative;
    overflow: hidden;

    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(https://oss.breal.co/certificate/cert-bg%403x.png);
      opacity: 0.1;
      background-size: cover;
      background-position: center;
    }

    .header {
      width: 100%;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      margin-bottom: calc(16 / 375 * @cert-width);

      .logo {
        width: calc(80 / 375 * @cert-width);
      }
    }

    .body {
      width: 100%;
      padding-top: calc(18 / 375 * @cert-width);
      padding-bottom: calc(24 / 375 * @cert-width);
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      background-color: fade(black, 86 * 0.2);
      border-radius: calc(12 / 375 * @cert-width);
      flex-grow: 1;

      .cert-title {
        margin-bottom: calc(16 / 375 * @cert-width);

        h1,
        h2,
        h3 {
          color: white;
          font-size: 1.7em;
          text-align: center;
          margin: 0;
          line-height: 1.4em;
        }

        h1 {
          font-weight: bold;
        }

        h2 {
          font-size: 1.1em;
          line-height: 1.4em;
          font-family: "Times New Roman", Times, serif;
          color: fade(white, 70);
          letter-spacing: 0.06em;
        }

        h3 {
          font-size: 0.62em;
          line-height: 1.1em;
          font-family: "Times New Roman", Times, serif;
          color: fade(white, 70);
          letter-spacing: 0.04em;
        }
      }

      .cover-image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: calc(5 / 375 * @cert-width);
        background-color: fade(black, 8);
        border-radius: calc(22 / 375 * @cert-width);
        position: relative;
        margin-bottom: calc(16 / 375 * @cert-width);
        width: calc(@cert-width * 0.48);
        height: calc(@cert-width * 0.52);

        .cover-image {
          border-radius: calc(16 / 375 * @cert-width);
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: cover;
        }
        .cert-badge {
          width: 56%;
          position: absolute;
          bottom: 0;
          right: 0;
          transform: translate(30%, 16%);
        }
      }

      .info-text-wrapper {
        box-sizing: border-box;
        width: 100%;
        padding: 0 calc(24 / 375 * @cert-width);
        flex-grow: 1;

        .info-text {
          width: 100%;
          padding-left: calc(4 / 375 * @cert-width);
          padding-right: calc(4 / 375 * @cert-width);
          padding-bottom: calc(3 / 375 * @cert-width);
          margin-bottom: calc(3 / 375 * @cert-width);
          border-bottom: 1px solid fade(white, 20);
        }
      }

      .qr-code-wrapper {
        padding: 6px 4px 4px 4px;
        background: fade(black, 20);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        margin-top: 16px;

        .qr-code {
          width: 58px;
          height: 58px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: yellow;
          border-radius: 4px;
          margin-bottom: 2px;
        }

        .scan-code-text {
          font-size: 10px;
        }
      }
    }

    .footer {
      color: fade(white, 30);
      margin-top: calc(8 / 375 * @cert-width);
      font-size: calc(11 / 375 * @cert-width);
      text-align: center;
    }
  }
}

