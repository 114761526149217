.shop-item-wrapper {
  margin: auto;
  max-width: 1180px;
  min-height: 640px;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  padding-top: 96px;
  @media screen and (min-width: 640px) {
    max-width: 640px;
  }

  .image-list {
    overflow-x: scroll;
    padding: 0px 24px 24px;

    .image-grid {
      display: grid;
      grid-auto-flow: column;
      overflow-x: visible;
      gap: 1em;
    }
  }

  .shop-item-img {
    border-radius: 1em;
    width: 100%;
    aspect-ratio: 3/3.5;
    object-fit: cover;
    box-shadow: 0 8px 12px fade(black, 16);
    user-select: none;
    // pointer-events: none;
  }

  .content {
    padding: 24px 24px 0px 24px;
    text-align: center;

    h1 {
      font-size: 1.5em;
      text-align: center;
      font-weight: 500;
      padding: 0 1em 0.4em 1em;
    }

    .description {
      text-align: center;
      font-size: 0.9em;
      opacity: 0.9;
      font-weight: 300;
    }

    .brand {
      text-transform: uppercase;
      font-size: 0.9em;
      margin-bottom: 0.4em;
    }

    .condition {
      border: 1px dashed fade(black, 40);
      display: inline-block;
      padding: 2px 16px;
      border-radius: 4px;
      margin-top: 0.4em;
    }

    .price {
      font-size: 1.2em;
      font-weight: bold;
      text-align: center;
      margin-top: 0.6em;
    }

    .search-id {
      text-align: center;
      font-size: 0.9em;
      span {
        font-size: 1.2em;
        font-weight: bold;
      }
    }
  }

  .btns-container {
    margin-bottom: 24px;
    .download-btn,
    .open-app-btn {
      border-radius: 6px;
      margin-top: 1em;
      background-color: fade(black, 80);
      color: white;
      border: none;
    }

    .open-app-btn {
      background-color: #cfa54a;
      font-weight: bold;
      margin: 1em auto 1em auto;
    }
  }
}

