.insturction-contents  {
  padding: 24px;

  h2 {
    font-size: 20px;
    margin: 16px 0 8px  0;
  }

  li {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}
