.html-content {
    box-sizing: border-box;
    padding: 24px;
    font-size: 10px !important;

    h3 {
        line-height: 1.8em;
        font-size: 24px;
        margin-top: 8px;
        margin-bottom: 2px;
    }

    p {
        line-height: 1.4em;
        font-size: 16px;
    }

}
